<template>
  <span>
    <span @click="decrement()" class="btn btn-xs btn-light-success btn-icon">
      <i class="ki ki-minus icon-xs"></i>
    </span>
    <input
      v-model="foo"
      type="number"
      :min="min"
      :step="step || 1"
      class="input-number text-center align-middle"
      :style="`width: ${width || 40}px;`"
    />
    <span @click="increment()" class="btn btn-xs btn-light-success btn-icon">
      <i class="ki ki-plus icon-xs"></i>
    </span>
  </span>
</template>
<style lang="scss">
.center {
  text-align: center;
}

/* Chrome, Safari, Edge, Opera */
input.input-number::-webkit-outer-spin-button,
input.input-number::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.input-number[type="number"] {
  -moz-appearance: textfield;
}
</style>
<script>
import bigDecimal from "js-big-decimal";

export default {
  props: ["defaultValue", "step", "decimal", "disabledAppend", "min", "width"],
  data() {
    return {
      foo: this.defaultValue ?? 0
    };
  },
  methods: {
    increment() {
      const val = bigDecimal.add(this.foo, Number(this.step) || 1);
      this.foo = Number(bigDecimal.round(val, Number(this.decimal) || 0));
    },
    decrement() {
      const min = Number(this.min);
      if (this.foo - (Number(this.step) || 1) < min) {
        return;
      }
      const val = Number(bigDecimal.subtract(this.foo, Number(this.step) || 1));
      this.foo = Number(bigDecimal.round(val, Number(this.decimal) || 0));
    }
  },
  watch: {
    foo: {
      handler: function() {
        this.$emit("newdata", this.foo);
        this.$emit("onChange", this.foo);
      },
      deep: true
    }
  }
};
</script>
